import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(({breakpoints, spacing}) => ({
  container: {
    padding: spacing(10, 0),
  },
  infoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: spacing(5),
    '& div': {
      maxWidth: '45%',
      flex: '50%',
    },
    '& h4': {
      paddingBottom: spacing(2),
    },
    [breakpoints.down('md')]: {
      flexDirection: 'column',
      '& div': {
        flex: '100%',
        maxWidth: '100%',

        marginBottom: spacing(5),
      },
    },
  },
}))

export default useStyles
