import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(({palette, breakpoints, shadow, spacing}) => ({
  container: {
    display: 'flex',
    maxWidth: '100%',
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%',
      alignItems: 'center',
    },
  },
  mainContainer: {
    display: 'flex',
    position: 'relative',
    top: spacing(-9.25),
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  subContainer: {
    width: '100%',
    [breakpoints.down('sm')]: {
      marginBottom: spacing(2.75),
    },
  },
  cardContainer: {
    background: palette.background.default,
    boxShadow: shadow.keys,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    '& > span': {
      paddingLeft: spacing(10),
      [breakpoints.down('sm')]: {
        paddingLeft: spacing(13),
      },
    },
    '& > h3': {fontSize: 30},
    '& > p': {
      fontSize: 18,
      marginTop: spacing(2.5),
      marginBottom: spacing(3.75),
    },
    '& > a': {
      [breakpoints.down('sm')]: {
        marginTop: spacing(3),
      },
    },
    [breakpoints.down('sm')]: {
      margin: spacing(3, 'auto'),
      minWidth: '100%',
      textAlign: 'center',
    },
  },
  calendarContainer: {
    backgroundColor: palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '5px 5px 12px 0 rgba(51, 51, 51, 0.1)',
    width: '58vw',
    marginLeft: -spacing(3),
    padding: spacing(5, 0),
    alignItems: 'center',
    justifyContent: 'space-around',
    textAlign: 'center',
    borderRadius: spacing(0, 1, 5, 0),
    [breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: 0,
      marginTop: -spacing(6),
    },
  },
  contentCalendarContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginRight: spacing(4),
    [breakpoints.down('sm')]: {
      paddingTop: spacing(5),
    },
  },
  financialDates: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddingRight: spacing(1),
  },
  financialNames: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    paddingLeft: spacing(1),
  },
  eventDate: {
    fontFamily: 'CamptonBook',
    fontSize: 20,
    height: 24,
    [breakpoints.down('sm')]: {
      fontSize: 15,
    },
  },
  eventName: {
    fontSize: 20,
    height: 24,
    marginRight: spacing(3),
    textAlign: 'center',
    [breakpoints.down('sm')]: {
      minWidth: '100%',
      marginRight: 0,
      fontSize: 15,
    },
    '& Button': {
      marginTop: spacing(5),
    },
  },
  left: {
    width: 300,
    height: 500,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: spacing(5, 1),
    marginRight: spacing(3),
    textAlign: 'center',
    '& Button': {
      marginTop: spacing(5),
    },
  },
  leftExpanded: {
    height: 500,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: spacing(5, 1),
    boxShadow: shadow.hard,
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginRight: 0,
    },
  },
  rightExpanded: {
    display: 'none',
  },
  right: {
    padding: spacing(4),
    minHeight: '47.5%',
    borderRadius: spacing(1, 5),
    [breakpoints.up('md')]: {
      '&:first-of-type': {
        marginBottom: spacing(3),
      },
    },
    [breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    '& p': {
      margin: 0,
      '&:first-of-type': {
        marginBottom: spacing(1),
      },
      '&:last-of-type': {
        marginTop: spacing(1),
        marginBottom: -spacing(1),
      },
    },
    [breakpoints.down('md')]: {
      padding: spacing(4, 0),
    },
  },
  cardMidContainer: {
    margin: 'auto 0px',
    [breakpoints.down('md')]: {
      margin: spacing(2, 0),
    },
  },
  cardSubContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& button': {
      minWidth: 'fit-content',
    },
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      margin: spacing(3, 'auto'),
    },
  },
  cardSubContainerText: {
    width: '70%',
    marginBottom: spacing(3),
  },
  dateFinancialCalendar: {
    justifyContent: 'center',
  },
  eventImage: {
    width: 180,
    padding: spacing(3, 0),
  },
  date: {
    fontFamily: 'CamptonLight',
    fontSize: '16px',
    color: palette.text.grey,
  },
  roundedButton: {
    boxShadow: 'none',
    padding: `${spacing(1.8)}px ${spacing(3.1)}px`,
    boxSizing: 'border-box',
    textTransform: 'none',
    fontFamily: 'CamptonSemiBold',
    fontSize: '1.12em',
    borderRadius: 35,
    height: 'min-content',
    backgroundColor: 'inherit',
    '& > span > span': {
      lineHeight: `${spacing(3)}px`,
    },
    [breakpoints.down('sm')]: {
      width: 'inherit',
    },
  },
  buttonLabel: {
    marginRight: spacing(1.5),
    alignItems: 'center',
    display: 'flex',
    color: 'white',
  },
  bordered: {
    borderStyle: 'solid',
    background: 'transparent!important',
    borderWidth: '1px',
    width: 'max-content',
    minWidth: 'fit-content',
    '&:hover': {
      backgroundColor: `${palette.primary.main}!important`,
      color: `${palette.text.light}!important`,
      borderColor: 'transparent!important',
    },
  },
  primary: {
    color: palette.primary.main,
    backgroundColor: `${palette.primary.main}!important`,
    border: palette.primary.main,
  },
  buttonArrowRight: {
    transform: 'rotate(225deg)',
    color: 'white',
  },
  buttonArrowLeft: {
    transform: 'rotate(45deg)',
    color: 'white',
  },
  noRadius: {
    borderRadius: spacing(5, 0, 0, 1),
  },
}))

export default useStyles
