import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(({spacing, breakpoints}) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  subSection: {
    display: 'flex',
    '& h3': {
      paddingBottom: spacing(4),
    },
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  divPaddingTop: {
    paddingTop: spacing(4),
  },
  iconPadding: {
    display: 'flex',
    '& > div': {
      paddingLeft: spacing(2),
    },
  },
  mailTextAlign: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      paddingLeft: spacing(2),
    },
  },
  subSectionLeft: {
    maxWidth: '30%',
    [breakpoints.down('sm')]: {
      maxWidth: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  subSectionRight: {
    maxWidth: '50%',
    '& h3': {
      margin: 0,
    },
    [breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  titleContainer: {
    paddingBottom: spacing(8),
  },
  phoneContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

export default useStyles
