import * as R from 'ramda'
import classnames from 'classnames'
import React from 'react'

import {shareholdersPropTypes} from 'helpers/propTypes'
import MailIcon from '@material-ui/icons/Mail'
import PhoneIcon from '@material-ui/icons/Phone'
import RawText from 'components/UI/RawText'
import RoundButton from 'components/UI/RoundButton'
import Title from 'components/UI/Title'

import useStyles from './styles'

const ShareHolders = ({
  investorsRelationsContactsEmail,
  investorsRelationsContactsPhone,
  investorsRelationsContactsTitle,
  investorsRelationsHeader,
  investorsRelationsMyKeyrusLink,
  investorsRelationsMyKeyrusLinkCta,
  investorsRelationsMyKeyrusLinkCtaLink,
  countryData,
}) => {
  const {countryCode, urlPrefix, navLocale} = countryData
  const classes = useStyles()

  const SubSection = ({left, right}) => (
    <div className={classnames(classes.subSection, classes.container)}>
      <div className={classes.subSectionLeft}>{left}</div>
      <div className={classes.subSectionRight}>{right}</div>
    </div>
  )

  const renderMediumTitle = text => (
    <Title
      variant="h3"
      type="mediumTitle"
      fontFamily="book"
      className={classes.titlePadding}
    >
      {text}
    </Title>
  )

  const renderContacts = (mail, phone) => (
    <div>
      <div className={classnames(classes.subSection, classes.mailTextAlign)}>
        <MailIcon fontSize="large" />
        <div>{mail}</div>
      </div>
      <div
        className={classnames(
          classes.subSection,
          classes.divPaddingTop,
          classes.iconPadding,
        )}
      >
        <PhoneIcon fontSize="large" />
        <div className={classes.phoneContainer}>
          <RawText text={phone} />
        </div>
      </div>
    </div>
  )

  const isWorldwide = R.includes(urlPrefix, 'worldwide')

  const uriContact = isWorldwide
    ? `/${countryCode}${investorsRelationsMyKeyrusLinkCtaLink}`
    : `/${countryCode}/${navLocale}${investorsRelationsMyKeyrusLinkCtaLink}`

  return (
    <div>
      <div className={classes.titleContainer}>
        <RawText text={investorsRelationsHeader} />
      </div>
      <SubSection
        left={
          <>
            {renderMediumTitle(investorsRelationsContactsTitle)}
            {renderContacts(
              investorsRelationsContactsEmail,
              investorsRelationsContactsPhone,
            )}
          </>
        }
        right={
          <>
            <RawText text={investorsRelationsMyKeyrusLink} />
            <div className={classes.divPaddingTop}>
              <RoundButton arrow href={uriContact}>
                {investorsRelationsMyKeyrusLinkCta}
              </RoundButton>
            </div>
          </>
        }
      />
    </div>
  )
}

ShareHolders.propTypes = shareholdersPropTypes

ShareHolders.defaultProps = {}

export default ShareHolders
