import * as R from 'ramda'
import classNames from 'classnames'
import React, {useState} from 'react'

import {eventsAndDocumentsPropTypes} from 'helpers/propTypes'
import {getNextDate} from 'helpers/utils'
import Button from '@material-ui/core/Button'
import CtaArrowIcon from '@material-ui/icons/CallReceived'
import DateComponent from 'components/UI/Date'
import RoundButton from 'components/UI/RoundButton'
import Title from 'components/UI/Title'
import useIsMobile from 'hooks/useIsMobile'

import eventImage from '../../../../images/investors-calendar-icon.png'
import useStyles from './styles'

const EventAndDocuments = ({
  eventsTitle,
  eventsMoreLabel,
  eventsNextLabel,
  eventsLessLabel,
  downloadCta,
  downloadMoreLabel,
  eventsItems,
  nodeLocale,
  financialNews,
  financialPublications,
  countryData,
  pageContext,
}) => {
  const {prefix} = pageContext
  const {countryCode, urlPrefix, navLocale} = countryData
  const classes = useStyles()
  const isMobile = useIsMobile()
  const mapIndexed = R.addIndex(R.map)

  const mostRecentFinancialNews = financialNews.documents.reduce(
    (previous, current) => {
      if (
        new Date(previous.publicationDate) < new Date(current.publicationDate)
      ) {
        return current
      }

      return previous
    },
    financialNews.documents[0],
  )

  const [isCollapsed, setIsCollapsed] = useState(false)

  const toFinancialNews = `/${countryCode}/${
    urlPrefix === 'worldwide' ? '' : `${navLocale}/`
  }${prefix}${financialNews.slug}`
  const toFinancialPublications = `/${countryCode}/${
    urlPrefix === 'worldwide' ? '' : `${navLocale}/`
  }${prefix}${financialPublications.slug}`

  const documentFinancialPublication = R.pathOr(
    null,
    ['documents', 0],
    financialPublications,
  )
  const eventDates = R.map(node => node.date, eventsItems)
  const nextDate = getNextDate(eventDates)
  const eventFormattedDates = R.map(node => node.formattedDate, eventsItems)
  const [firstEventFormattedDates] = eventFormattedDates

  const renderFinancialCalendarDates = () =>
    mapIndexed(
      (node, index) => (
        <p className={classes.eventDate} key={index}>
          <DateComponent
            timeCode={node.date}
            node_locale={nodeLocale}
            rawDate={firstEventFormattedDates}
          />
        </p>
      ),
      eventsItems,
    )

  const renderFinancialCalendarNames = () =>
    mapIndexed(
      (node, index) => (
        <p className={classes.eventName} key={index}>
          {node.name}
        </p>
      ),
      eventsItems,
    )

  const leftContainer = () => (
    <div className={classes.container}>
      <div
        className={classNames(
          classes.cardContainer,
          classes.left,
          isCollapsed ? classes.noRadius : null,
        )}
      >
        <Title variant="h4" type="mediumTitle">
          {eventsNextLabel}
        </Title>
        <img
          className={classes.eventImage}
          src={eventImage}
          alt=""
          loading="lazy"
        />
        {nextDate.date && (
          <>
            <Title variant="h4" type="innerTitle">
              {R.pathOr('', ['name'], eventsItems[nextDate.index])}
            </Title>
            <DateComponent
              timeCode={nextDate.date}
              node_locale={nodeLocale}
              className={classes.dateFinancialCalendar}
              rawDate={firstEventFormattedDates}
            />
          </>
        )}
        <Button
          variant="contained"
          onClick={() => setIsCollapsed(!isCollapsed)}
          className={classNames(
            classes.roundedButton,
            classes.bordered,
            classes.primary,
          )}
        >
          <span className={classes.buttonLabel}>
            {!isCollapsed ? eventsMoreLabel : eventsLessLabel}
          </span>
          <CtaArrowIcon
            className={classNames(
              !isCollapsed ? classes.buttonArrowRight : classes.buttonArrowLeft,
            )}
          />
        </Button>
      </div>
      {isCollapsed ? (
        <div className={classes.calendarContainer}>
          <Title variant="h3" type="subTitle" fontFamily="book">
            {eventsTitle}
          </Title>
          <div className={classes.contentCalendarContainer}>
            <div className={classes.financialDates}>
              {renderFinancialCalendarDates()}
            </div>
            <div className={classes.financialNames}>
              {renderFinancialCalendarNames()}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )

  const rightContainer = (firstDocument, secondDocument) => (
    <>
      <div
        className={classNames(
          classes.cardContainer,
          !isCollapsed ? classes.right : classes.rightExpanded,
        )}
      >
        <div className={classes.cardMidContainer}>
          <div className={classes.cardSubContainer}>
            <div className={classes.cardSubContainerText}>
              <Title
                variant="h4"
                type="innerTitle"
                hat={financialNews.pageTitle}
              >
                {firstDocument.name}
              </Title>
              <p className={classes.date}>{firstDocument.publicationDate}</p>
            </div>
            <RoundButton
              color="secondary"
              downloadArrow
              href={firstDocument.file.file.url}
              isCentered={isMobile}
              fileName={firstDocument.file.file.fileName}
              isAction
              isDownload
            >
              {downloadCta}
            </RoundButton>
          </div>
          <RoundButton arrow color="primary" href={toFinancialNews}>
            {downloadMoreLabel}
          </RoundButton>
        </div>

        <div className={classes.cardMidContainer}>
          <div className={classes.cardSubContainer}>
            <div className={classes.cardSubContainerText}>
              <Title variant="h4" type="innerTitle">
                {secondDocument.name}
              </Title>
              <p className={classes.date}>{secondDocument.publicationDate}</p>
            </div>
            <RoundButton
              color="secondary"
              downloadArrow
              href={secondDocument.file.file.url}
              isCentered={isMobile}
              fileName={secondDocument.file.file.fileName}
              isAction
              isDownload
            >
              {downloadCta}
            </RoundButton>
          </div>
          <RoundButton arrow color="primary" href={toFinancialPublications}>
            {downloadMoreLabel}
          </RoundButton>
        </div>
      </div>
    </>
  )

  function getLastTwoItems(arr) {
    if (arr.length < 2) {
      // Handle the case where the array has less than two items
      return arr
    }

    const lastIndex = arr.length - 1

    return [arr[lastIndex - 1], arr[lastIndex]]
  }

  const twoLastDocuments = getLastTwoItems(financialPublications.documents)

  return (
    <div className={classes.mainContainer}>
      {leftContainer()}
      <div className={classes.subContainer}>
        {rightContainer(twoLastDocuments[1], twoLastDocuments[0])}
      </div>
    </div>
  )
}

EventAndDocuments.propTypes = eventsAndDocumentsPropTypes

EventAndDocuments.defaultProps = {}

export default EventAndDocuments
