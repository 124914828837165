import React from 'react'

import {mainCardPropTypes} from 'helpers/propTypes'
import ContentSection from 'components/UI/ContentSection'
import FormatQuoteIcon from '@material-ui/icons/FormatQuote'

import useStyles from './styles'

const MainCard = ({description, ceo}) => {
  const classes = useStyles()

  const {
    photo: {file},
    jobTitle,
    linkedInProfile,
    name,
  } = ceo

  return (
    <div className={classes.container}>
      <ContentSection
        title={<FormatQuoteIcon className={classes.icon} />}
        description={description}
        variation="No image"
      />
      <div className={classes.linkedInContainer}>
        <a href={linkedInProfile} rel="noopener noreferrer">
          <img src={file.url} alt="" loading="lazy" />
        </a>
        <div>
          <p className={classes.nameCeo}>{name}</p>
          <p className={classes.jobCeo}>{jobTitle}</p>
        </div>
      </div>
    </div>
  )
}

MainCard.propTypes = mainCardPropTypes

MainCard.defaultProps = {}

export default MainCard
